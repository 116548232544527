<template>
  <div class="analysis_warp">
    <div class="top">
      <TopCard ref="TopCardRef"
               text="答案解析"
               :showBack="true" />
    </div>
    <div class="content">
      <div class="left">
        <div class="topic_content">
          <!-- <p class="title">题目{{questionContent['paper_question_no']}}
          </p> -->
          <div class="topic"
               v-html="question.question_head_content"></div>
          <div class="topic"
               v-html="question.question_content"></div>
        </div>
      </div>
      <div class="right">
        <TopicInfo ref="TopicInfoRef"
                   :question="question"
                   :opertion_heigth="0" />
      </div>
    </div>
  </div>
</template>

<script>
import TopCard from '@/views/components/top_card/index.vue'
import TopicInfo from './opertion.vue'
import { favoriteInfo } from '@/api/errorbasis.js'
export default {
  data () {
    return {
      list: [],
      index: 0,
      info: {},
      question: {}
    }
  },
  components: {
    TopCard, TopicInfo
  },
  computed: {
    // questionContent () {
    //   this.list[this.index] = this.list[this.index] ? this.list[this.index] : {
    //     paper_question_no: '',
    //     question_code_head: '',
    //     question_content: '',
    //   }
    //   return this.list[this.index]
    // }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      let params = {
        question_id: this.$route.query.question_id
      }
      const { data } = await favoriteInfo(params)
      this.question = data
    },
  }
}
</script>

<style lang="scss" scoped>
.analysis_warp {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #f6f6f6;
  .top {
    width: 100vw;
    height: 100rem;
  }
  .content {
    display: flex;
    height: calc(100vh - 100rem);
    .left {
      flex: 1;
      background: #ffffff;
      margin-left: 50rem;
      .topic_content {
        padding: 30rem;
        overflow: auto;
        .title {
          font-size: 20rem;
          font-weight: 500;
          color: #666666;
        }
        .head {
          font-size: 20rem;
          font-weight: 500;
          color: #000000;
          margin-top: 20rem;
        }
        div {
          margin-top: 20rem;
        }
      }
    }
    .right {
      flex: 1;
      background: #ffffff;
      margin-left: 60rem;
    }
  }
}
.fixed_btn {
  position: fixed;
  top: 50rem;
  right: 30rem;
  font-size: 24rem;
  display: flex;
  // width: 350rem;
  div {
    flex: 1;
    text-align: center;
  }
  img {
    width: 36rem;
    height: 36rem;
    margin-left: 30rem;
  }
}
.topic {
  font-size: 20rem;
}
</style>
<template>
  <div class="opertion_wrap">
    <div class="head">
      <!-- <div class="left">
        题目ID:{{$rands(question.question_id)}}
      </div> -->
      <div class="right">
        <span class="question_score">
          题目分数：{{question.question_score}}
        </span>
        <!-- <span class="my_score">
          我的得分: <span style="color:#FF6147">{{question.user_score}}</span>
        </span> -->
        <!-- <span class="img_warp">
          <img src="@/assets/self_correct/发帖.png"
               @click="addDiscuss"
               alt="">
          <img src="@/assets/self_correct/纠错.png"
               @click="feed"
               alt="">
          <img src="@/assets/self_correct/收藏.png"
               v-if="!question.has_favorite"
               @click="collect"
               alt="">
          <img src="@/assets/self_correct/已收藏.png"
               v-else
               alt="">
        </span> -->
      </div>
    </div>
    <div class="content"
         :style="{'height':`calc(100% - ${opertion_heigth?330:0}rem)`}">
      <div class="coll_wrap">
        <el-collapse v-model="activeNames"
                     @change="handleChange">

          <el-collapse-item title="题目内容"
                            v-if="$route.path == '/self_correct'"
                            name="1">
            <div v-if="question.question_head_content"
                 class="topic"
                 v-html="question.question_head_content"></div>
            <div class="topic"
                 v-html="question.question_content"></div>
          </el-collapse-item>
          <el-collapse-item title="题目解析"
                            name="2">
            <div class="topic"
                 v-html="question.question_explain"></div>
          </el-collapse-item>
          <el-collapse-item title="题目答案"
                            name="3">
            <div class="topic"
                 v-html="question.question_answer"></div>
          </el-collapse-item>
          <el-collapse-item title="我的答案"
                            name="4">
            <div class="topic"
                 v-html="question.user_answer"></div>
          </el-collapse-item>
          <!-- <el-collapse-item title="我的评语"
                            name="5">
            <div>
              {{question.comment_to_user}}
            </div>
          </el-collapse-item> -->
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      num: 0,
      activeNames: ['1', '2', '3'],
    }
  },
  props: {
    opertion_heigth: {
      type: Number,
      default: 240
    },
    question: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  methods: {

    handleChange () { },
  }
}
</script>

<style lang="scss" scoped>
.opertion_wrap {
  position: relative;
  height: 100%;
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 68rem;
    padding: 0 30rem;
    background: #eff8ff;
    .left {
      text-indent: 30rem;
      font-size: 20rem;
      font-weight: 500;
      color: #333333;
      display: flex;
      align-items: center;
      height: 36rem;
    }
    .right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .question_score {
        font-size: 20rem;
        font-weight: bold;
        color: #000000;
        margin-right: 30rem;
      }
      .my_score {
        font-size: 20rem;
        font-weight: bold;
        color: #000000;
        margin-right: 30rem;
      }
      img {
        width: 36rem;
        height: 36rem;
        margin-right: 30rem;
      }
    }
  }
  .content {
    width: calc(100% - 60rem);
    overflow: auto;
    background: #ffffff;
    margin-left: 30rem;
    .coll_wrap {
      margin-top: 30rem;
    }
  }
  .fixed_btn {
    position: absolute;
    width: 100%;
    background: #ffffff;
    box-shadow: 0rem 2rem 10rem 4rem rgba(0, 0, 0, 0.05);
    border: 1rem solid #d9d9d9;
    bottom: 0;
    .line {
      margin-top: 20rem;
      display: flex;
      .label {
        font-size: 20rem;
        font-weight: bold;
        color: #000000;
        text-align: right;
        height: 50rem;
        line-height: 50rem;
        margin-left: 50rem;
        padding-right: 15rem;
        cursor: pointer;
        img {
          width: 50rem;
          height: 50rem;
        }
      }
      .input_wrap {
        flex: 1;
        .btn_wrap {
          display: flex;
          margin-right: 100rem;
          .btn {
            flex: 1;
            height: 50rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            font-size: 20rem;
            font-weight: bold;
            background: #fe765f;
            border-radius: 25rem;
            cursor: pointer;
            &:nth-child(1) {
              margin-left: 30rem;
              margin-right: 30rem;
              background: #3f9eff !important;
            }
          }
        }
      }
    }

    ::v-deep .el-input--medium .el-input__inner {
      height: 50rem;
      line-height: 50rem;
    }
    ::v-deep .el-input-number__decrease,
    .el-input-number__increase {
      height: 47rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    ::v-deep .el-input-number__increase {
      height: 47rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

::v-deep .el-collapse-item__header {
  background: #f6f6f6;
  border-radius: 10rem;
  font-size: 20rem;
  font-weight: 500;
  color: #333333;
  text-indent: 20rem;
  margin-top: 20rem;
}
::v-deep .el-collapse-item__wrap {
  border-bottom: none;
}
::v-deep .el-collapse {
  border: none;
}
::v-deep .el-collapse-item__content {
  margin-left: 20rem;
  margin-top: 20rem;
}
::v-deep .el-collapse-item__arrow.is-active {
  margin-top: -20rem;
}
.topic {
  font-size: 20rem;
}
</style>